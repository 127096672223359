import { data } from 'autoprefixer';
import axios from 'axios';

const baseUrl = 'https://public-api.capterra.com/v1/';

export const getdata = async ()=>{
    console.log('testtest');
    try{
        const {data} = await axios.get(baseUrl + 'clicks');
        return data;
    }catch(error){
        throw error;
    }
}