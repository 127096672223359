import axios from "axios";

// const instance = axios.create({
//     baseURL: "https://public-api.capterra.com/v1/", 
//     timeout: 1000 * 100,  
//     headers:{
//         'Authorization': 'ZCzf5cikCqJmgwN7',
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'Access-Control-Allow-Origin': '*',
//     }
// });

const instance = axios.create({
    baseURL: "https://public-api.capterra.com/v1/",// "https://localhost:44325/v1/",
    timeout: 1000 * 100,
    headers: {
        Authorization: 'ZCzf5cikCqJmgwN7',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json',
    }
});

export default instance;